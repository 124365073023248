<template>
  <div id="V2About">
    <div class="about">
      <div class="top">
        <div class="title">
          {{$t("about.aboutPage.title")}}
        </div>
        <div class="description">
          {{$t("about.aboutPage.description")}}
        </div>
      </div>
      <div class="content">
        <div class="about-background">
          <template v-for="(item, i) in 11">
            <video
              :key="i"
              poster="/img/threespace/media/about_movement.png"
              autoplay
              muted
              loop
              playsinline
            >
              <source src="/img/threespace/media/about_movement.mp4" type="video/mp4" />
              video 요소를 지원하지 않는 브라우저입니다. 동영상은 다운로드 후 볼 수 있습니다.
            </video>
          </template>
        </div>

        <div class="about-card">
          <img src="/img/threespace/about/about-nft-gallery.png" />
          <div class="text-wrap">
            <div class="about-card-title">
              {{$t("about.aboutCard.nftTitle")}}
            </div>
            <div class="about-card-description">
              {{$t("about.aboutCard.nftDescription")}}
            </div>
          </div>
        </div>
        <div class="about-card">
          <img src="/img/threespace/about/about-artpool.png" />
          <div class="text-wrap">
            <div class="about-card-title">
              {{$t("about.aboutCard.artpoolTitle")}}
              <b-button @click="onClickArtpool">
                {{ $t("button.learnMore") }}
              </b-button>
            </div>
            <div class="about-card-description">
              {{$t("about.aboutCard.artpoolDescription")}}
            </div>
          </div>
        </div>
        <div class="about-card">
          <img src="/img/threespace/about/about-exhibit.png" />
          <div class="text-wrap">
            <div class="about-card-title">
              {{$t("about.aboutCard.exhibitTitle")}}
            </div>
            <div class="about-card-description">
              {{$t("about.aboutCard.exhibitDescription")}}
            </div>
          </div>
        </div>
        <div class="about-card">
          <img src="/img/threespace/about/about-pace.png" />
          <div class="text-wrap">
            <div class="about-card-title">
              {{$t("about.aboutCard.paceTitle")}}
              <b-button @click="onClickWhitePaper">
                {{ $t("button.learnMore") }}
              </b-button>
            </div>
            <div class="about-card-description">
              {{$t("about.aboutCard.paceDescription")}}
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="partners">
          <div class="title">{{ $t('product.partnersTitle') }}</div>
          <div class="partners-wrap">
            <template v-for="(item, i) in partners">
              <b-img :src="`/img/threespace/partners/` + item.fileName" :key="i"/>
            </template>
          </div>
        </div>
        <div class="partners">
          <div class="title">BACKERS</div>
          <div class="partners-wrap">
            <template v-for="(item, i) in backers">
              <b-img :src="`/img/threespace/partners/` + item.fileName" :key="i"/>
            </template>
          </div>
        </div>
        <div class="team">
          <div class="title">TEAM</div>
          <div class="team-wrap">
            <template v-for="(item, i) in teams">
              <div class="item" :key="i">
                <div class="img-container"><CImg :src="`/img/threespace/teams/` + item.fileName"/></div>
                <div class="name">{{item.name}}</div>
                <div class="role">{{item.role}}</div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "About",
  metaInfo() {
    return {
      title: this.$t("meta.about.title") + " | 3space Art",
      meta: [
        { vmid: "title", name: "title", content: this.$t("meta.about.title") + " | 3space Art" },
        { vmid: "description", name: "description", content: this.$t("meta.about.description") },
        { vmid: "og:title", property: "og:title", content: this.$t("meta.about.title") + " | 3space Art" },
        { vmid: "og:description", property: "og:description", content: this.$t("meta.about.description") },
        { vmid: "og:url", property: "og:url", content: window.location.origin + this.$route.path }
      ]
    }
  },
  data() {
    return {
      partners: [
        { fileName: "shinhan_card.png" },
        { fileName: "LX.png" },
        { fileName: "HDC_Labs.png" },
        { fileName: "HyundaiOilbank.png" },
        { fileName: "komsco.png" },
        { fileName: "ncc_logo-variations-final_.png" },
        { fileName: "groundx.png" },
        { fileName: "polygon.png" },
        { fileName: "korbit.png" },
        { fileName: "HashKeyNFT.png" },
        { fileName: "NFTAsia.png" },
        { fileName: "HAVAH.png" },
        { fileName: "SOMESING.svg" },
        { fileName: "BLUE_CANVAS.png" },
        { fileName: "neopin.png" },
        { fileName: "ym-star-entertainment.png" },
        { fileName: "tae-young.svg" },
        { fileName: "art-dubai.png" },
        { fileName: "bnk.png"},
      ],
      backers: [
        { fileName: "MODORI.png" },
        { fileName: "ALPHA_NONCE_xbg.png" },
        { fileName: "499BLOCK.png" },
        { fileName: "FBG_Capital.png" },
        { fileName: "intellicode.png" },
        { fileName: "kaia-combination-black.png" },
        { fileName: "kibo.png" },
      ],
      teams: [
        { fileName: "1.png", name: "Uz Jang", role: "PRODUCT MANAGER / KOR"},
        { fileName: "2.png", name: "Hanna Joo", role: "MANAGEMENT / KOR" },
        { fileName: "3.png", name: "Yeonhee Cho", role:"FULL STACK DEVELOPER / KOR" },
        { fileName: "4.png", name: "Yoon Kim", role:"FOUNDER / SGP"},
        { fileName: "5.png", name: "Michale Chung", role:"FINANCE / KOR" },
        { fileName: "6.png", name: "Daniel Aldridge", role: "SOFTWARE ENGINEER / USA" },
        { fileName: "7.png", name: "Hiroshi Muto", role: "CURATOR / JPN"},
        { fileName: "8.png", name: "Sunny An", role: "HEAD OF DESIGN / KOR" },
        { fileName: "9.png", name: "Julius N'point", role: "CURATOR / DEU" },
        { fileName: "10.png", name: "Kyle Kim", role: "STRATEGY / BKK" },
        { fileName: "11.png", name: "Khaye Duze", role: "SYSTEM INTEGRATION / ZAF" },
        { fileName: "12.png", name: "Luka Lee", role:"BLOCKCHAIN ENGINEER / KOR" },
      ],
      defaultLanguage: "en"
    };
  },
  computed: {
    activeLanguage() {
      return this.getActiveLanguage();
    }
  },
  methods: {
    getActiveLanguage() {
      return localStorage.getItem("language") || this.defaultLanguage;
    },
    onClickWhitePaper() {
      window.open("https://docs.3space.art/", "_blank");
    },
    onClickArtpool() {
      this.$router.push("/art-pool/nft");
    }
  }
};
</script>
